._{
    font-size: 14px;
    font-weight: 600;
    border-radius: 30px;
    height: 40px;
    padding: 0 15px;
    background: var(--hint-color);
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s ease;
    position: relative;
    z-index: 0;
}
.input{
    font-size: 14px;
    font-weight: 400;

    border: none;
    width: 70px;
    background: var(--hint-color);
    color: var(--text-color);
}