.image{
    width: 100%;
    border-radius: 6px 6px 0 0;

}
.swiper_block{
    position: relative;
    width: 100%;
    height: 45vh;
}
.swiper_custom {
    height: 100%;

}
.navigation {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 10;
  }
  
  .prevButton,
  .nextButton {
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    color: black;
    font-size: 24px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .prevButton:hover,
  .nextButton:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .swiper_slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper_slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }