@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --main-font: "Open Sans", sans-serif;
  --color-grey: #5F7385;
  --color-white: #fff;
  --color-black: #000;
  --accent-text-color:var(--tg-theme-accent-text-color, #6ab2f2);
  --color-scheme:var(--tg-color-scheme, dark);
  --bg-color:var(--tg-theme-bg-color, #17212b);
  --button-color:var(--tg-theme-button-color, #5288c1);
  --button-text-color:var(--tg-theme-button-text-color, #ffffff);
  --destructive-text-color:var(--tg-theme-destructive-text-color, #ec3942);
  --header-bg-color:var(--tg-theme-header-bg-color, #17212b);
  --hint-color:var(--tg-theme-hint-color, #708499);
  --link-color:var(--tg-theme-link-color, #6ab3f3);
  --secondary-bg-color:var(--tg-theme-secondary-bg-color, #232e3c);
  --section-bg-color:var(--tg-theme-section-bg-color, #17212b);
  --section-header-text-color:var(--tg-theme-section-header-text-color, #6ab3f3);
  --subtitle-text-color:var(--tg-theme-subtitle-text-color, #708499);
  --text-color:var(--tg-theme-text-color, #f5f5f5);
  /* --text-green: var(--tg-theme-text-color, #beff8b); */
  /* --dark-gray: var(--tg-theme-hint-color, #23323f); */
  /* --black-text-color: var(--tg-theme-button-text-color, #11141b); */
}
BODY{
  margin: 0;
  background: var(--bg-color);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Apple Color Emoji", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.App {
  overflow: auto;
  height: 100vh;
  position: relative;
  text-align: center;
  font-family: var(--main-font);
}

input:focus, textarea:focus, select:focus {
  outline: none !important;
}

.wrapper {
  max-width: 90%;
  margin: 0 auto;
}

a {
  text-decoration: none;
}
.top-bar {
  height: 33px;
  display: flex;
  justify-content: flex-end; 
  align-items: center; 
}
