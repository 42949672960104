  .iconContainer {
    padding: 0 10px;
    position: relative;
    height: 100%;
    overflow: hidden;
    display: inline-block;
  }
  .basketIcon {
    width: 30px;
    height: 30px;
    object-fit: cover;
    cursor: pointer;
  }
  .basketIcon img{
    width: 100%;
    object-fit: contain;
  }
  .itemCount {
    position: absolute;
    top: 16px;
    left: 4px;
    background-color: var(--destructive-text-color);
    color: var(--text-color);
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 9px;
    font-weight: bold;
}