/* components/SearchInput/SearchInput.module.css */

.searchContainer {
    display: flex;
    align-items: center;
    width: 100%;
}

.searchInput {
    background: var(--hint-color);
    flex: 1;
    padding: 1px 20px;
    height: 40px;
    border: 1px solid var(--bg-color);
    color: var(--text-color);
    border-radius: 30px;
    font-size: 16px;
    font-weight: 550;
    cursor: pointer;
}
::placeholder { /* Most modern browsers support this now. */
    color:    var(--text-color);
 }
