.sectionsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    justify-content: center;
}

.sectionTile {
    display: flex;
    justify-content: space-between;
    width: 80%;
    background-color: var(--button-color);
    opacity: 50%;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    padding: 15px;
    color: var(--button-text-color);
    transition: background-color .3s;
}

.sectionTile:hover {
    background-color: #e0e0e0;
}
.sectionTileName{
    align-self: center;
    text-align: center;
}
.sectionTileImage{
    height: 50px;
}
