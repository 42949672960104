._{
    position: relative;
}
.select{
    font-size: 14px;
    font-weight: 600;
    border-radius: 30px;
    height: 40px;
    padding: 1px 20px;
    background: var(--hint-color);
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all .3s ease;
    position: relative;
    z-index: 0;
}
.selectOpen{
    border-radius: 15px 15px 0 0;
    background: var(--hint-color);
    color: var(--text-color);
    z-index: 2;
}

.options{
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    height: 40px;
    padding-left: 15px;
    padding-right: 9px;
    display: flex;
    align-items: center;
    background: var(--hint-color);
    color: var(--text-color);
    border-top: 1px solid var(--secondary-bg-color);
    transition: all .3s ease;
}
.options:last-of-type{
    border-radius: 0 0 30px 30px;
}
.selectBlock{
    position: absolute;
    width: 100%;
    margin-left: -1px;
    background: var(--hint-color);
    border: 1px solid var(--secondary-bg-color);
    border-top: none;
    border-radius: 0 0 12px 12px;
    z-index: 3;
    max-height: 280px;
    overflow: auto;
}
.radio{
    width: 15px;
    height: 15px;
    margin-right: 10px;
}
.fon{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
}
.str{
    min-width: 14px;
    min-height: 8px;
}
.icon{
    height: 20px;
}
.iconBlock{
    font-weight: 400;
    font-size: 14px;
    display: flex;
    gap: 5px;
    color: var(--color-black);
}