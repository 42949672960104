.errorOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--bg-color); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; 
  }
  
.errorContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--bg_color);
  font-size: 18px;
  font-weight: bold;
}
  
.errorMessage {
    font-size: 18px;
    margin-bottom: 20px;
    color: var(--text-color);
    text-align: center;
  }

.emojiAnimation {
    font-size: 120px; 
    animation: bounce 2s infinite; 
}

@keyframes bounce {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
}

  