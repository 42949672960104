/* components/basketBtn/BasketBtn.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    background: var(--secondary-bg-color);
    width: 40%;
    justify-content: center;
}

.addToBasket {
    background-color: var(--secondary-bg-color);
    color: var(--text-color);
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 16px;
    transition: background-color 0.3s ease;
    width: 100%;
    text-align: center;
}

.addToBasket:hover {
    background-color: var(--destructive-text-color);
}

.quantityControl {
    display: flex;
    align-items: center;
    gap: 10px;
}

.decrease,
.increase {
    background-color: var(--text-color);
    color: var(--bg-color);
    border: none;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 10px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.decrease:hover,
.increase:hover {
    background-color: var(--destructive-text-color);
}

.quantity {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-color);
    padding: 8px 12px;
    border: 1px solid var(--bg-color);
    border-radius: 10px;
    text-align: center;
}
