._{
    background: var(--secondary-bg-color);
    border-radius: 6px;
    width: calc(50% - 5px);
    height: 10.5rem;
    position: relative;
    cursor: pointer;
}
@media (max-width: 460px) {
    ._ {
        height: 13rem;
    }
}
.info{
    padding: 5px 5px 5px 5px;
    gap: 5px;
    display: flex;
    flex-direction: column;
}
.block{
    display: flex;
    justify-content: space-between;
}
.block_low {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.price{
    font-weight: 600;
    font-size: 18px;
    color: var(--text-color);
    margin: 0;
}
.area{
    font-weight: 400;
    font-size: 12px;
    color: var(--text-color);
    margin: 0;
}
.type{
    font-weight: 400;
    font-size: 12px;
    color: var(--text-color);
    margin: 0;
}
.loc{
    font-weight: 600;
    font-size: 12px;
    color: var(--text-color);
    margin: 0;
}
.image{
    width: 100%;
    border-radius: 6px 6px 0 0;
}
