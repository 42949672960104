@keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

.container {
    opacity: 0;
    /*transition: opacity 1s cubic-bezier(0.42, 0, 0.58, 1);*/
    animation: slideIn 0.5s ease-in-out forwards; /* Плавный переход для изменения прозрачности */
  }
  
  .visible {
    opacity: 1;
  }

._{
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    top: 0;
    left: 0;
    z-index: 3;
    background: var(--bg-color);
    overflow: auto;
    padding: 10px;
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.block1{
    background: var(--bg-color);
    border-radius: 10px;
}
.image{
    border-radius: 10px;
    width: 100%;
}
.name{
    font-weight: 600;
    font-size: 20px;
    color: var(--text-color);
    padding: 10px 10px 0 10px;
    width: 70%;
    line-height: 27px;
    text-align: left;
    width: 90%;
}
.block1_2{
    display: flex;
    justify-content: right;
    gap: 10px;
    padding: 0px 10px 10px 10px;
}
.address{
    font-weight: 400;
    font-size: 16px;
    color: var(--text-color);
}
.price{
    font-weight: 700;
    font-size: 22px;
    color: var(--text-color);
    white-space: nowrap;
}
.like{
    transition: opacity .3s;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
}
.infoBlock{
    position: relative;
}
.block1_5 {
    
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: space-evenly;

}
.block1_5_1 {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px 10px;
    background: var(--secondary-bg-color);
    width: 30%;
    justify-content: center;
}
.infoText{
    font-weight: 400;
    font-size: 16px;
    color: var(--text-color);
}
.infoText2{
    font-weight: 700;
    font-size: 16px;
    color: var(--text-color);
}
.block2_1{
    display: flex;
    gap: 2px;
    flex-direction: column;
    width: calc(45%);
    text-align: center;
}
.block3{
    background: var(--secondary-bg-color);
    border-radius: 10px;
    padding: 15px 10px;
}
.infoText3{
    font-weight: 600;
    font-size: 16px;
    color: var(--text-color);
}
.infoText4{
    font-weight: 400;
    font-size: 14px;
    text-align: justify;
    color: var(--text-color);
    opacity: 0.9;
    padding-top: 10px;
}
.infoText5 {
    color: var(--text-color);
    font-size: 17px;
    font-weight: 600;
    text-align: center;
}
.infoText6 {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}
.block3_1{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
    flex-direction: column;
}
.iconBlock{
    font-weight: 400;
    font-size: 16px;
    display: flex;
    gap: 5px;
    color: var(--text-color);
}
.icon{
    height: 20px;
}