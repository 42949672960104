.image{
    width: 100%;
    border-radius: 6px 6px 0 0;
    object-fit: cover;
}
.image img{
    width: 100%;
    object-fit: contain;
}
.image_box {
    position: relative;
    width: 100%;
    height: 70%;
    overflow: hidden;
    display: flex;
}