/* components/basket/Basket.module.css */

.basketContainer {
    padding: 20px;
    background: var(--bg-color);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.basketItem {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px;
    border-bottom: 1px solid var(--secondary-bg-color);
}

.itemImage {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    object-fit: cover;
}

.itemDetails {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-grow: 1;
}

.itemName {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-color);
}

.itemQuantity {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-color);
}


.itemQuantityControl {
    display: flex;
    align-items: center;
    justify-content: center;
}

.decrease,
.increase {
    background-color: var(--secondary-bg-color);
    color: var(--text-color);
    border: none;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 10px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}
.decrease:hover,
.increase:hover {
    background-color: var(--destructive-text-color);
}

.quantity {
    width: 30px;
    text-align: center;
    color: var(--text-color)
}

.itemPrice {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-color);
}

.total {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    border-top: 2px solid var(--primary-color);
    font-size: 18px;
    font-weight: 600;
    color: var(--text-color);
}

.totalLabel {
    font-size: 18px;
}

.totalSum {
    font-size: 18px;
}
