/* BearLoading.css */
.loading_container {
    display: flex;
    justify-content: center; /* Выравнивание по горизонтали */
    align-items: center; /* Выравнивание по вертикали */
    height: 100vh; /* Высота контейнера равна высоте видимой области окна браузера */
    width: 100vw;

  }
.loading_container img {
  display: block; /* Делаем изображение блочным элементом, чтобы можно было задавать ему отступы */
  margin: 0 auto; /* Центрируем изображение по горизонтали внутри контейнера */
}
  